<template>
  <header class="container-fluid border-bottom fixed-top">
    <div class="row bar"></div>
    <div class="row py-3 d-flex align-items-center">
      <p class="h2 int-name text-uppercase mb-0 ml-3"><b-link to="/">Intersect<span>3P</span></b-link></p>
      <b-button variant="link" class="ml-auto btn-lg p-0 mr-3" v-b-toggle.main-nav>
        <font-awesome-icon :icon="['fas', 'bars']"/>
      </b-button>
      <b-sidebar id="main-nav" no-header  shadow backdrop backdrop-variant="dark">
        <Nav></Nav>
      </b-sidebar>
    </div>
  </header>
</template>

<script>
import Nav from '@/components/global/Nav';

export default {
  name: "Header",
  components: {
    Nav
  }
}
</script>

<style lang="scss">
header {
  background-color: $gray-100;
  .int-name{
    a{
      color: $gray-700;
      &:focus,
      &:hover{
        text-decoration: none;
      }
    }
  }

  .bar {
    background-color: $gray-700;
    @include rem(padding, 3px 0);
  }
}
.b-sidebar{
  &.bg-light{
    background: desaturate($brand, 15%) !important;
    ul{
      li{
        a{
          color: $gray-200;
        }
      }
    }
  }
}
</style>
