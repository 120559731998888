import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import About from '@/views/About';
import Contact from '@/views/Contact';
import Experience from '@/views/companies/Experience';
import Confidentiality from '@/views/companies/Confidentiality';
import CompaniesHome from '@/views/companies/CompaniesHome';
import OurServices from '@/views/companies/OurServices';
import CandidatesHome from '@/views/candidates/CandidatesHome';
import CandidateResources from '@/views/candidates/CandidateResources';
import Positions from '@/views/candidates/Positions';
import Position from '@/views/Position';
import SubmitResume from '@/views/candidates/SubmitResume';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    component: About
  },
  {
    path: '/contact',
    name: 'Contact',
    component: Contact
  },

  {
    path: '/companies',
    component: CompaniesHome,
    children: [
      {
        path: 'experience',
        name: 'Experience',
        component: Experience
      }, {
        path: 'confidentiality',
        name: 'Company Confidentiality',
        component: Confidentiality

      }, {
        path: 'our-services',
        name: 'Our Services',
        component: OurServices

      }
    ]
  },
  {
    path: '/candidates',
    component: CandidatesHome,
    children: [
      {
        path: 'position/:id',
        name: 'Position',
        component: Position
      },
      {
        path: 'candidate-resources',
        name: 'Candidate Resources',
        component: CandidateResources
      },
      {
        path: 'confidentiality',
        name: 'Candidate Confidentiality',
        component: Confidentiality

      },
      {
        path: 'positions',
        name: 'Positions',
        component: Positions

      },
      {
        path: 'submit-resume',
        name: 'Submit Resume',
        component: SubmitResume

      }
    ]
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  scrollBehavior: () => ({x: 0, y: 0}),
  routes
})

export default router
