import Vue from 'vue'
import App from './App.vue'
import { BootstrapVue } from 'bootstrap-vue';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

// global styles
import './styles/main.scss';
import router from './router'
import { faFacebookF } from '@fortawesome/free-brands-svg-icons/faFacebookF';
import { faLinkedinIn } from '@fortawesome/free-brands-svg-icons/faLinkedinIn';
import { faTwitter } from '@fortawesome/free-brands-svg-icons/faTwitter';
import { faBars } from '@fortawesome/free-solid-svg-icons/faBars';
import { faAsterisk } from '@fortawesome/free-solid-svg-icons/faAsterisk';
import { faUser } from '@fortawesome/free-solid-svg-icons/faUser';
import { faUsers } from '@fortawesome/free-solid-svg-icons/faUsers';
import { faCompass } from '@fortawesome/free-regular-svg-icons/faCompass';
import Meta from 'vue-meta';
import VueGtag from 'vue-gtag';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons/faEnvelope';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons/faMapMarkerAlt';

Vue.config.productionTip = false

// global components
Vue.component('font-awesome-icon', FontAwesomeIcon);

Vue.use(BootstrapVue);
Vue.use(Meta, {
  refreshOnceOnNavigation: true
});

// global icons
library.add(
  faFacebookF,
  faLinkedinIn,
  faTwitter,
  faBars,
  faAsterisk,
  faUser,
  faUsers,
  faCompass,
  faEnvelope,
  faMapMarkerAlt
);

Vue.use(VueGtag, {
  config: {id: 'UA-49689746-1'},
  appName: 'Intersect3P',
  pageTrackerTemplate (to) {
    return {
      page_path: to.path
    }
  }
}, router);

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
