<template>
  <section class="confidentiality container">
    <div class="row">
      <div class="col-lg-12">
        <h1 class="page-title">Confidentiality</h1>

      </div>
      <div class="col-lg-8" v-if="this.isCompanies">
        <p>We see ourselves as an extension of your company, so when you provide private information critical to the
          search, you can rest easily knowing it we will not share it with anyone unauthorized to have it. Candidates
          will not know the identity of your company until we are satisfied with their level of interest as well as
          their level of qualification.</p>
      </div>
      <div class="col-lg-8" v-else>
        <p>Changing positions is not an easy decision or process, but Intersect can help. By speaking with us candidly,
          we can get to know not only what your raw experience is, but also all of the other things that will influence
          your interest in a company or position. It will also help us understand your career goals. By providing us
          with this information and insight, you will know that when we contact you about an opportunity, it is very
          likely to be a good match!
        </p>
        <p>
          The information you share with us, including your resume, will be held in confidence. We will not “blast” your
          resume to employers hoping to find a fit for your career. Our database is for our use only; potential
          employers will receive your information only when you have had a chance to review the opportunity and decide
          if it is something you would like to pursue.</p>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Confidentiality",
  metaInfo: {
    title: 'Confidentiality'
  },
  data: () => ({
    isCompanies: false
  }),
  mounted () {
    this.isCompanies = (this.$route.path === '/companies/confidentiality');
  }
}

</script>

<style scoped>

</style>
