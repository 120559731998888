<template>
  <nav>
    <ul class="nav flex-column">
      <li class="nav-item">
        <router-link to="/">Home</router-link>
      </li>
      <li class="nav-item">
        <router-link to="/about">About</router-link>
      </li>
      <li class="nav-item">
        <b-button class="btn-block text-left"
                  variant="link"
                  :class="companiesExpanded ? null : 'collapsed'"
                  :aria-expanded="companiesExpanded ? 'true' : 'false'"
                  aria-controls="companies-collapse"
                  @click="companiesExpanded = !companiesExpanded">Companies</b-button>
        <b-collapse id="companies-collapse" v-model="companiesExpanded">
          <ul class="list-unstyled">
            <li>
              <router-link to="/companies/confidentiality">Confidentiality</router-link>
            </li>
            <li>
              <router-link to="/companies/experience">Experience</router-link>
            </li>
            <li>
              <router-link to="/companies/our-services">Our Services</router-link>
            </li>
          </ul>
        </b-collapse>
      </li>
      <li class="nav-item">
        <b-button
            class="btn-block text-left"
            variant="link"
            :class="candidatesExpanded ? null : 'collapsed'"
            :aria-expanded="candidatesExpanded ? 'true' : 'false'"
            aria-controls="candidates-collapse"
            @click="candidatesExpanded = !candidatesExpanded">Candidates</b-button>
        <b-collapse id="candidates-collapse" v-model="candidatesExpanded">
          <ul class="list-unstyled">
            <li>
              <router-link to="/candidates/candidate-resources">Candidate Resources</router-link>
            </li>
            <li>
              <router-link to="/candidates/confidentiality">Confidentiality</router-link>
            </li>
            <li>
              <router-link to="/candidates/positions">Positions</router-link>
            </li>
            <li>
              <router-link to="/candidates/submit-resume">Submit Resume</router-link>
            </li>
          </ul>
        </b-collapse>
      </li>
      <li class="nav-item">
        <router-link to="/contact">Contact</router-link>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  name: "Nav",
  data: () => ({
    companiesExpanded: false,
    candidatesExpanded: false
  }),
  watch:{
    $route (){
      this.companiesExpanded = false;
      this.candidatesExpanded = false;
    }
  }
}
</script>

<style lang="scss" scoped>
nav {
  > ul {
    li {
      .btn {
        color: $white;
        font-family: 'OpenSansSemibold';
        @include rem(font-size, 17px);
        @include rem(padding, 20px 0);
        @include rem(padding-left, 25px);

        &:after {
          content: '+';
          height: 10px;
          width: 10px;
          display: inline-block;
          @include rem(font-size, 20px);
          line-height: 1;
          @include rem(margin-left, 15px);
        }
      }

      a {
        display: block;
        @include rem(font-size, 14px);
        @include rem(padding, 20px 0);
        @include rem(padding-left, 25px);

        &.router-link-exact-active {
          background: darken(desaturate($brand, 15%), 10%);
        }
      }
    }

    > li {
      border-bottom: 1px inset desaturate($indigo, 45%);
    }

    ul {

      li {

      }
    }
  }
}
</style>
