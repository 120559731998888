import { render, staticRenderFns } from "./CompaniesHome.vue?vue&type=template&id=7062dbd2&scoped=true&"
import script from "./CompaniesHome.vue?vue&type=script&lang=js&"
export * from "./CompaniesHome.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7062dbd2",
  null
  
)

export default component.exports