<template>
  <footer>
    <section class="top py-5 border-top">
      <div class="container">
        <div class="row">
          <div class="col-md-6 col-lg-3">
            <h2 class="int-name text-uppercase">Intersect<span>3P</span></h2>
            <address class="d-flex flex-column">
              <span>P.O. Box 191</span>
              <span>Lovingston‚ VA 22949</span>
            </address>
            <p class="mb-0 mt-4" v-if="false">Phone: <a class="phoneNumberLink" href="tel:434-326-4400">434-326-4400</a></p>
            <p>Email: <a href="mailto:info@intersect-3p.com">
              info@intersect-3p.com
            </a></p>
          </div>
          <div class="col-md-6 col-lg-3"></div>
          <div class="col-md-6 col-lg-3"></div>
          <div class="col-md-6 col-lg-3">
            <h3>About Us</h3>
            <p>Intersect is an executive search firm focused on providing talent solutions and HR outsourcing for the
              Healthcare IT market.</p>
            <ul class="list-inline social-media mt-6">
              <li class="list-inline-item">
                <a href="http://www.linkedin.com/in/lindseyeltzroth" class="btn btn-primary" target="_blank"
                   title="Connect with Intersect3P on Linkedin">
                  <font-awesome-icon :icon="['fab', 'linkedin-in']"/>
                </a>
              </li>
              <li class="list-inline-item" v-if="false">
                <a href="" class="btn btn-primary fb" target="_blank" title="Follow Intersect3P on Facebook">
                  <font-awesome-icon :icon="['fab', 'facebook-f']"/>
                </a>
              </li>
              <li class="list-inline-item">
                <a href="http://www.twitter.com/Intersect3p" class="btn btn-primary" target="_blank"
                   title="Follow Intersect3P on Twitter">
                  <font-awesome-icon :icon="['fab', 'twitter']"/>
                </a>
              </li>

            </ul>
          </div>
        </div>
      </div>
    </section>
    <section class="bottom py-3 border-top border-dark">
      <div class="container">
        <div class="d-flex justify-content-between">
          <p>&copy;{{year}} Copyright Intersect 3P LLC. All rights reserved.</p>
        </div>
      </div>
    </section>
  </footer>
</template>

<script>
export default {
  name: "Footer",
  data: () => ({
    year: ''
  }),
  mounted () {
    this.year = new Date().getFullYear();
  }
}
</script>

<style lang="scss">
footer {
  background-color: $gray-200;
  margin-top: auto;

  .top {
    @include rem(font-size, 13px);

    address {

    }

    .social-media {
      .btn {
        @include rem(border-radius, 100px);
        @include rem(padding, 7px 14px);

        &.fb {
          @include rem(padding, 7px 16px);
        }

        &:focus,
        &:hover {
          color: #e57200;
        }
      }
    }
  }

  .bottom {
    background-color: $brand-2;
    color: $white;
    @include rem(font-size, 12px);

    p {
      margin-bottom: 0;
    }


  }
}
</style>
