<template>
  <div class="wrapper" id="app">
    <Header></Header>
    <main>
      <transition
          name="fade"
          mode="out-in"
      >
        <router-view></router-view>
      </transition>
    </main>
    <Footer></Footer>
  </div>
</template>
<script>
import Header from '@/components/global/Header';
import Footer from '@/components/global/Footer';

export default {
  components: {
    Header,
    Footer
  },
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: 'Intersect3P',
    // all titles will be injected into this template
    titleTemplate: '%s | Intersect3P',
    htmlAttrs: {
      lang: 'en'
    }
  }
}
</script>
<style lang="scss">

</style>
