<template>
<section class="experience container">
  <div class="row">
    <div class="col-12">
      <h1 class="page-title">Our Experience</h1>
    </div>
    <div class="col-8">
      <p>
        With a decade of experience focused on placing professionals in the healthcare IT market, Intersect is uniquely positioned to deliver the  right candidates when you need them. Whether you need a top producing sales  executive or a visionary leader, Intersect can help you. We speak with  candidates and hiring managers every day, so we know who is ready for a change. Finding &nbsp;talent is difficult, and more often than not, a top performer is not actively searching for a new opportunity. Our experience and connections provide us the chance to quickly reach these hidden candidates when you need them the most.
      </p>
      <p>
        Our customized approach to your specific needs can involve:
      </p>
      <ul>
        <li>
          Completing a needs analysis profile
        </li>
        <li>
          Candidate research, screening&nbsp; and writing  of presentation
        </li>
        <li>
          Scheduling of interviews and advisement of interview techniques
        </li>
        <li>
          Candidate and client debriefs
        </li>
        <li>
          Reference checks
        </li>
        <li>
          Offer preparation and advising
        </li>
        <li>
          Client and new hire care
        </li>
      </ul>
      <p>
        Give Intersect the chance to understand your company’s  culture and goals‚ and we will use that to tailor a search to identify the  candidate best suited for the position. We know it isn’t just about what you  need on paper — it’s also about finding the person who really clicks with your team.
      </p>
      <p>
        Email
        <a href="mailto:Info@intersect-3p.com">
          Info@intersect-3p.com
        </a>
        to inquire about our abilities to partner with you.
      </p>
    </div>
  </div>
</section>
</template>

<script>
export default {
  name: "Experience",
  metaInfo: {
    title: 'Experience'
  }
}
</script>

<style scoped>

</style>
