<template>
  <section class="submit-resume container">
    <div class="row">
      <div class="col-lg-12">
        <h1 class="page-title">Submit Your Resume</h1>
      </div>
      <div class="col-lg-8">
        <p>
          Ready to start an active search? Curious about what types of  positions are open for someone with your background? Interested in having a  quick conversation about the next step in your career?
        </p>
        <p>
          Send your resume to <a href="mailto:Info@Intersect-3p.com">Info@Intersect-3p.com</a> to get a conversation scheduled.
        </p>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "SubmitResume",
  metaInfo: {
    title: 'Submit Your Resume'
  }
}
</script>

<style scoped>

</style>
