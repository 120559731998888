<template>
  <section class="positions container">
    <div class="row">
      <div class="col-12">
        <h1 class="page-title">Candidate Positions</h1>
      </div>
      <div class="col-lg-8">
        <p>
          Turn your assets into something tangible! Intersect focuses on placing executives, managers, and experienced
          professionals in the healthcare IT market. Leveraging over a decade of experience and thousands of
          connections, we are constantly working to place people where they will make a difference.
        </p>
        <p>
          Our open positions are listed below. If something piques your interest, please send your resume to
          <a href="mailto:info@intersect-3p.com">
            info@intersect-3p.com
          </a>
          with the job title in the subject line.
        </p>
        <div class="iframe-container embed-responsive embed-responsive-16by9">
          <iframe id="PCR" src="https://www.pcrecruiter.net/pcrbin/regmenu.aspx?uid=intersect.intersect"
                  class="embed-responsive-item"></iframe>
        </div>
      </div>
      <div class="col-lg-4 d-none d-lg-block">
        <img src="../../../public/resources/image/puzzle.jpg" alt="" class="d-block mx-auto rounded">
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Positions",
  metaInfo: {
    title: 'Positions'
  }
}
</script>

<style lang="scss">
.iframe-container{
  @include rem(min-height, 650px);
  @include media-breakpoint-down(xs){
    @include rem(min-height, 750px);
  }
  @include media-breakpoint-between(sm,md){
    @include rem(min-height, 725px);
  }
}
</style>
