<template>
  <section class="container">
    <div class="row">
      <template v-if="position === 'web-app-developer'">
        <div class="col-12 page-title">
          <h1>Web App Developer</h1>
        </div>
        <div class="col-lg-10">
          <p>
            An award winning healthcare technology company is searching for a Web App Developer to join their team.
          </p>
          <p>
            You will be a member of a small agile development team that values quality and collaboration. The position
            will require you to participate in group design meetings, author effective and concise code, and write
            meaningful unit tests. As a member of the team, you will interact with all stakeholders of the business,
            including customers, management, vendors, and non-technical staff.
          </p>
          <p>
            Candidates must have 3+ years of web development experience and must be able to demonstrate fluency in at
            least 3 of the following:
          </p>
          <ul>
            <li>
              jQuery/JavaScript
            </li>
            <li>
              MVC Framework (AngularJS, Backbone, Knockout)
            </li>
            <li>
              ASP.NET Web API
            </li>
            <li>
              Microsoft SQL Server (T-SQL)
            </li>
            <li>
              WCF .NET Framework 4+
            </li>
            <li>
              .NET Entity Framework
            </li>
          </ul>
          <p></p>
          <p>The company is well known for hiring ambitious, energetic, self-driven individuals who thrive in open
            environments.</p>
          <p>Houston, TX candidates <em>preferred</em>.</p>
          <p class="lead my-5"><strong>Ready to get started?</strong></p>
          <p>
            Send resume
            <a href="mailto:info@intersect-3p.com?subject=Job Title - Web App Developer">
              info@intersect-3p.com</a>.
          </p>
        </div>
      </template>
      <template v-else-if="position === 'product-owner'">
        <div class="col-12 page-title">
          <h1>Product Owner</h1>
        </div>
        <div class="col-lg-10">
          <p>
            The Product Owner manages the entire product backlog and translates product strategy into prioritized
            actionable tasks. Works with cross-functional agile teams to make sure they are executing on requirements.
            Ensures that product backlog is visible and clear to all stakeholders, optimizing the value of the work the
            Development Team performs.
          </p>
          <p>
            Requires a bachelor’s degree and 10+ years of relevant experience. Should be proficient in C#, ASP.NET Core,
            html, JasvScript, and MS-SQL. Extensive experience leading cross-functional project teams. Project
            Management Professional certification is preferred.
          </p>
          <p>
            This is an exempt position and reports into Development.
          </p>

          <p class="lead my-5"><strong>Ready to get started?</strong></p>
          <p>
            Send resume
            <a href="mailto:info@intersect-3p.com?subject=Job Title - Product Owner">
              info@intersect-3p.com</a>.
          </p>
        </div>
      </template>
      <template v-else-if="position === 'sales-executive'">
        <div class="col-12 page-title">
          <h1>Sales Executive</h1>
        </div>
        <div class="col-lg-10">
          <p>
            The Sales Executive position will primarily sell a cloud based service line to doctor practices of one to
            six providers. As a Sales Executive, you will be responsible for managing the full life cycle of sales,
            including prospecting, selling, and closing. The ideal candidate has experience selling, as well as proven
            sales presentation and demonstration skills.
          </p>
          <p>
            Candidates should have a bachelor's degree and a minimum of 2 years of experience achieving success in
            carrying a sales quota.
          </p>
          <p>
            The company offers salary, commissions, and bonus.
          </p>
          <p class="lead my-5"><strong>Ready to get started?</strong></p>
          <p>
            Send resume
            <a href="mailto:info@intersect-3p.com?subject=Job Title - Sales Executive">
              info@intersect-3p.com</a>.
          </p>
        </div>
      </template>
      <template v-else-if="position === 'national-account-sales'">
        <div class="col-12 page-title">
          <h1>National Account Sales</h1>
        </div>
        <div class="col-lg-10">
          <p>
            The National Account Sales Director will be responsible for building relationships including health systems
            and academic medical centers throughout the US. Candidates need to have experience orchestrating enterprise
            deals from discovery to close with multiple C-level contacts. This is a new business sales position
            representing the company's core product lines. Candidates who are in enterprise sales roles now or who
            manage a team of enterprise sales executives are ideal.
          </p>
          <p>
            Candidates should have experience selling clinical and financial solutions to hospital based physician
            groups or academic medical centers and a demonstrated record of success closing multimillion dollar
            contracts. Executive level communication skills and the ability to navigate the complex nature of long sales
            cycles are required. Bachelor's Degree is strongly preferred.
          </p>
          <p>
            The company is well known for rewarding their employees through multiple avenues.
          </p>
          <p>
            THIS IS <em><strong>NOT</strong></em> AN OPPORTUNITY YOU WANT TO MISS!
          </p>
          <p class="lead my-5"><strong>Ready to get started?</strong></p>
          <p>
            Send resume
            <a href="mailto:info@intersect-3p.com?subject=National Account Sales">
              info@intersect-3p.com</a>.
          </p>

        </div>
      </template>
      <template v-else-if="position === 'channel-development-associate'">
        <div class="col-12 page-title">
          <h1>Channel Development Associate</h1>
        </div>
        <div class="col-lg-10">
          <p>
            The Channel Development Associate position will be a core contributor in marketing, sales, and relationship
            management efforts to support growth across 3rd party partnerships. As a Channel Development Associate, you
            will work closely with colleagues in various internal departments and with key partner contacts.
          </p>
          <p>
            Candidates should have a bachelor's degree. The ideal candidate will have proven collaboration skills,
            particularly with stakeholder colleagues from other internal departments. Candidate experience should
            include the ability to drive engagement and influence others, as well as a strong willingness to develop
            sales and marketing skills.
          </p>
          <p>
            The company offers salary and commissions. Regional travel will be necessary, with a target of 60%.
          </p>
          <p class="lead my-5"><strong>Ready to get started?</strong></p>
          <p>
            Send resume
            <a href="mailto:info@intersect-3p.com?subject=Job Title - Channel Development Associate">
              info@intersect-3p.com</a>.
          </p>
        </div>
      </template>
      <template v-else>
        <div class="col-12 page-title">
          <h1>Position not found</h1>
        </div>
        <div class="col-lg-9">
          <p>Ooops, the position you are looking for could not be found. Try
            <router-link to="/candidates/positions">searching our database</router-link>
            for the position or a similar position.
          </p>
          <p>Send us an <a href="mailto:info@intersect-3p.com">email</a> to get started on your next career move.</p>
        </div>
      </template>
    </div>
  </section>
</template>

<script>

export default {
  name: "Position",
  metaInfo: {
    title: 'Open Position'
  },
  data: () => ({
    position: String
  }),
  mounted () {
    this.position = this.$route.params.id;
  },
  methods: {}
}
</script>

<style lang="scss">

</style>
