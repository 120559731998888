<template>
  <section class="about">
    <div class="container my-5 pb-5">
      <div class="row">
        <div class="col-12">
          <h1 class="mb-6 page-title">About Intersect</h1>
        </div>
      </div>
      <div class="row flex-lg-row-reverse align-items-lg-center pb-5">
        <div class="col-lg-4 my-6">
          <img src="../../public/resources/image/intersect3p-president.jpg" class="img-circle profile-pic d-block mx-auto" alt="">
        </div>
        <div class="col-lg-8">
          <p class="big">
            Founded by Lindsey Eltzroth in 2014, Intersect3P is an executive search firm focused on placing
            professionals in the healthcare IT market. For over 20 years, we have confidently partnered with
            healthcare IT companies and candidates to build knowledgeable and productive teams in sales,
            marketing, project management, and development. Time and again, we are recognized for our open and
            direct communication, professionalism, and strategic sourcing abilities. By carefully evaluating
            client and candidate search criteria before we deploy innovative, customized plans, we create
            successful relationships, and our clients know that we will not overwhelm them with resumes while
            our candidates know that we will not inundate them with unrealistic job opportunities.
          </p>
        </div>
      </div>
    </div>
  </section>
</template>
<script>

export default {
  name: "About",
  metaInfo: {
    title: 'About'
  }

}
</script>
<style lang="scss">
.about {
  .profile-pic{
    @include rem(max-width, 200px);
  }
  .big {
    @include rem(font-size, 18px);
  }
}
</style>
