<template>
  <section class="searches container">
    <div class="row">
      <div class="col-lg-12">
        <h1 class="page-title">Our Services</h1>
      </div>
      <div class="col-lg-8">
        <h2>Fractional Recruiting</h2>
      </div>
      <div class="col-lg-10">
        <p>
          Fractional Recruiting allows our recruiting team to plug in whenever and wherever you need them!</p>
        <p>
          Intersect can support your recruiting efforts by taking the lead on searches or by helping with
          overflow tasks such as phone screens or reference checks, during peak hiring periods, covering
          maternity/paternity leaves, or lend the expertise needed to fill a particularly challenging
          position. Further, with over 20 years of experience and knowledge of healthcare IT industry trends,
          our team can help you design or refine your recruitment and interview processes to make sure you are
          attracting healthcare industry top talent.
        </p>
        <p>
          For small companies, growth frequently comes in spurts, and hiring a full-time recruiter can drain
          capital. By utilizing our Fractional Recruiting services, you can have a dedicated recruiter for
          5-20 hours a week as needed, freeing up resources for other projects. For larger companies, whether
          there is a valued recruiting team member out for an extended period of time or a hiring surge
          occurs, Fractional Recruiting allows you the ability support your recruiting team without the hassle
          and cost of hiring a full time replacement or a temp with no healthcare IT knowledge.
        </p>
        <p>
          What are the benefits to using Fractional Recruiting?
        </p>
        <ul>
          <li>Dedicated recruiting resource, acting as a full member of your team, without the FTE costs
            (benefits, etc.)
          </li>
          <li>Access to the 20+ years of healthcare IT industry experience of the Intersect Recruiting Team
          </li>
          <li>Flexibility to use us as long or as little as you need</li>
          <li>Use our team per-project, on monthly retainer, or at an hourly rate</li>
        </ul>

      </div>
      <div class="col-lg-8 mt-5">
        <h2>Search Services</h2>
      </div>
      <div class="col-lg-10">
        <p>
          We offer our clients the ability to customize their search plans. Whether it is a contingency search
          or a retained search, we can flex to meet the needs of your company.
        </p>
        <p>
          What are the benefits of using contingency and retained searches?
        </p>
        <ul>
          <li>Quick access to candidates on a per project basis</li>
          <li>Extra help on hard-to-fill roles</li>
          <li>Contract terms apply to all searches, leading to lasting partnership; you will not need to train
            a new recruiter with each new search
          </li>
        </ul>

        <p>
          It may be helpful to have an idea of the types of Our Services. Our clients focus on
          providing solutions for physicians, hospitals, managed care organizations, pharmacies, labs, state
          and federal government entities, and pharmaceutical companies. Below is a partial list of executive,
          management and skilled professional positions to consider:
        </p>
        <ul>
          <li>VP Sales</li>
          <li>VP Product Management</li>
          <li>VP Customer Care</li>
          <li>Director of Sales</li>
          <li>Director of Marketing</li>
          <li>Director of Product Management</li>
          <li>Sales Executive</li>
          <li>Demo Specialist</li>
          <li>Clinical (RN, PA, NP) Consultant (demo and project management)</li>
          <li>Physician Consultant</li>
          <li>Channel Sales</li>
          <li>Key Account Sales</li>
          <li>Account Manager</li>
          <li>Customer Technical Support</li>
          <li>Project Manager</li>
          <li>Implementation Analyst</li>
          <li>Regional Sales Manager</li>
          <li>Trainer</li>
          <li>Client Onboarding Specialist</li>
          <li>Medical Billing Specialist</li>
          <li>Customer Care Representative</li>
          <li>Physician Liaison</li>
        </ul>
        <p>
          Email
          <a href="mailto:Info@intersect-3p.com">
            Info@intersect-3p.com
          </a>
          to find out if we can help you with your next search!
        </p>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "OurServices",
  metaInfo: {
    title: 'Our Services'
  }
}
</script>

<style scoped>

</style>
