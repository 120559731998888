<template>
  <section class="contact">
    <div id="sidebar">
      <div class="container my-5 pb-5">
        <div class="row">
          <div class="col-12">
            <h1 class="mb-6 page-title">Contact Intersect</h1>
          </div>
          <section class="col-lg-6">
            <h4>Address</h4>
            <address>
              <p>
                <span class="d-block">
                  <font-awesome-icon class="mr-4 fa-primary" :icon="['fas', 'map-marker-alt']"/> P.O. Box 191 Lovingston&sbquo; VA 22949
                </span>
                <span class="d-block" v-if="false">
                  <font-awesome-icon class="mr-4 fa-primary" :icon="['fas', 'user']"/>
                  <a class="phoneNumberLink" href="tel:434-326-4400">434-326-4400</a>
                </span>
                <span class="d-block">
                  <font-awesome-icon class="mr-4 fa-primary" :icon="['fas', 'envelope']"/>
                  <a href="mailto:info@intersect-3p.com">info@intersect-3p.com</a>
                </span>
              </p>
            </address>
          </section>
          <section class="col-lg-4">
            <h4>Business Hours</h4>
            <ul class="list-unstyled">
              <li>Monday &mdash; Friday: <span>9am to 5pm</span></li>
              <li>Saturday: <span>Closed</span></li>
              <li>Sunday: <span>Closed</span></li>
            </ul>
          </section>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Contact",
  metaInfo: {
    title: 'Contact'
  }
}
</script>

<style scoped>

</style>
