<template>
  <section class="candidate-resources container">
    <div class="row">
      <div class="col-lg-12">
        <h1 class="page-title">Candidate Resources</h1>
      </div>
      <div class="col-lg-8">
        <p>
          We are here to provide more than just an alert about new opportunities. Relying on a decade of experience in
          executive recruiting, Intersect can provide advice in the following areas:
        </p>
        <ul>
          <li>
            Career planning
          </li>
          <li>
            Resume writing
          </li>
          <li>
            Using social media
          </li>
          <li>
            Interview techniques
          </li>
          <li>
            Offer negotiation and resignation
          </li>
          <li>
            Counter offers
          </li>
        </ul>
        <p><a href="mailto:info@intersect-3p.com">Contact us</a> today to see how we can advance your career.</p>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "CandidateResources",
  metaInfo: {
    title: 'Resources'
  }
}
</script>

<style scoped>

</style>
