<template>
  <section class="home">
    <div>
      <b-carousel
          id="carousel-1"
          class="hero-carousel"
          v-model="slide"
          :interval="4000"
          controls
          indicators
          background="#ababab"
          img-width="1024"
          img-height="480"
          style="text-shadow: 1px 1px 2px #333;"
          @sliding-start="onSlideStart"
          @sliding-end="onSlideEnd"

      >
        <b-carousel-slide
            v-for="(slide,index) in content.carousel"
            :key="index"
            :caption="slide.slideHeading"
            :img-src="slide.image">
          <p>{{ slide.slideText }}</p>
        </b-carousel-slide>
      </b-carousel>
    </div>
    <div class="container highlights mb-10 my-lg-10 pt-lg-8">
      <div class="row">
        <div class="col-md-6 col-lg-3 position d-flex align-items-stretch" v-for="(position, index) in content.position"
             :key="index">
          <div class="card">
            <div class="card-body d-flex flex-column">
              <font-awesome-icon v-bind:icon="[position.iconPrefix,position.icon]" class="mx-auto d-block border"/>
              <h2 class="h4 card-title text-center my-3 text-uppercase">{{ position.title }}</h2>
              <p class="card-text mb-7">{{ position.description }}</p>
              <router-link :to="position.buttonLink" class="btn btn-outline-dark mt-auto">{{
                  position.buttonText
                }}
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container testimonials">
      <div class="row">
        <div class="col-12">
          <h2 class="h3">Testimonials</h2>
        </div>
        <div class="col-md-5">
          <p>We are proud of our client relationships, and it gives us particular happiness when our
            clients, candidates, and partners take the time to acknowledge their satisfaction.
          </p></div>
        <div class="col-md-7">
          <b-carousel
              id="carousel-2"
              :interval="5000"
              fade
              background="#757575"
          >
            <b-carousel-slide
                v-for="(slide,index) in content.quotesCarousel"
                :key="index" class="quotes-slides">
              <blockquote class="blockquote text-left">
                <p class="mb-0">{{ slide.quote }}</p>
                <p class="blockquote-footer">{{ slide.author }}
                  <cite title="Source Title" v-if="(slide.company !== '')">{{ slide.company }}</cite></p>
              </blockquote>
            </b-carousel-slide>
          </b-carousel>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
// @ is an alias to /src
import json from '../../public/content/home.json';

export default {
  name: 'Home',
  metaInfo: {
    title: 'Welcome'
  },
  data: () => ({
    slide: 0,
    sliding: null,
    content: json

  }),
  methods: {
    onSlideStart () {
      this.sliding = true
    },
    onSlideEnd () {
      this.sliding = false
    }
  }
}
</script>
<style lang="scss">
.hero-carousel {
  .carousel-caption {
    background-color: rgba($black, .6);
    @include media-breakpoint-down(sm) {
      @include flexbox();
      @include justify-content(center);
      @include flex-direction(column);
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
    }
    @include media-breakpoint-up(lg) {
      background-color: rgba($black, .4);
      left: 25%;
      right: 25%;
    }
  }

  .carousel-control-prev,
  .carousel-control-next,
  .carousel-indicators {
    @include media-breakpoint-down(sm) {
      display: none !important;
    }
  }
}

.highlights {
  .position {
    @include media-breakpoint-down(md) {
      @include rem(margin-top, 100px);
    }

    .card-body {
      svg {
        @include rem(border-radius, 100px);
        @include rem(padding, 15px);
        margin-top: -62px;
        background: $white;
        @include rem(font-size, 97px);
        @include rem(height, 90px);
        @include rem(width, 90px);
      }
    }

    .card-title {
      @include rem(min-height, 40px);
    }
  }
}

.testimonials {
  .carousel-item {
    @include rem(min-height, 310px);
    @include media-breakpoint-down(sm) {
      @include rem(min-height, 300px);
    }
    @include flexbox();

    .carousel-caption {
      @include flexbox();
      @include align-items(center);
      @include media-breakpoint-down(sm) {
        bottom: 0;
        @include rem(margin-left, 8px);
        @include rem(margin-right, 8px);
      }

      margin-left: 15%;
      margin-right: 15%;
      left: 0;
      right: 0;
      position: relative;
    }

    .blockquote {
      @include rem(font-size, 14px);
    }

    .blockquote-footer {
      color: $gray-300;
    }
  }
}
</style>
